import {
  newTransactionLimit,
  editTransactionLimit,
  deleteTransactionLimit,
  getTransactionLimit,
} from '@services/queries'
import {
  DELETE_LIMIT_MESSAGE,
  DELETE_LIMIT_MESSAGE_FAILURE,
  CREATE_LIMIT_MESSAGE,
  CREATE_LIMIT_MESSAGE_FAILURE,
  UPDATE_LIMIT_MESSAGE,
  UPDATE_LIMIT_MESSAGE_FAILURE,
} from '@constants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { store } from 'react-notifications-component'

export function useNewTransactionLimitMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (limit) => {
      return newTransactionLimit(limit.limitObject)
    },
    {
      onSuccess(data, limit) {
        queryClient.invalidateQueries(['transaction_limit', limit.playerId])
        if (onSuccess) {
          limit.handleCreateLimit(limit.limitObject, data.result)
          onSuccess(data)
        }
        if (data) {
          store.addNotification(CREATE_LIMIT_MESSAGE(limit.limitObject.type))
        }
      },
      onError(err, limit) {
        console.error('Error updating player limits', err)
        store.addNotification(
          CREATE_LIMIT_MESSAGE_FAILURE(limit.limitObject.type),
        )
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export function useTransactionLimit(playerId) {
  const info = useQuery(['transaction_limit', playerId], () =>
    getTransactionLimit(playerId),
  )

  return info
}

export function useEditTransactionLimitMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (limit) => {
      return editTransactionLimit(limit.id, limit.limitObject)
    },
    {
      onSuccess(data, limit) {
        queryClient.invalidateQueries(['transaction_limit', limit.playerId])
        if (onSuccess) onSuccess(data)

        if (data)
          store.addNotification(UPDATE_LIMIT_MESSAGE(limit.limitObject.type))
      },
      onError(err, limit) {
        console.error('Error updating player limits', err)
        store.addNotification(
          UPDATE_LIMIT_MESSAGE_FAILURE(limit.limitObject.type),
        )
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export function useDeleteTransactionLimitMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (limit) => {
      return deleteTransactionLimit(limit.id)
    },
    {
      onSuccess(data, limit) {
        queryClient.invalidateQueries(['transaction_limit', limit.playerId])
        if (onSuccess) onSuccess(data)
        limit.handleDeleteLimitResponse(limit.update)
        store.addNotification(DELETE_LIMIT_MESSAGE(limit.type))
      },
      onError(err, limit) {
        console.error('Error updating player limits', err)
        store.addNotification(DELETE_LIMIT_MESSAGE_FAILURE(limit.type))
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}
