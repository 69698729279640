import {
  cilPlus,
  cibProbot,
  cibLogstash,
  cilApplicationsSettings,
  cilArrowRight,
  cilWallet,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilDrop,
  cilEnvelopeLetter,
  cilNoteAdd,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilPencil,
  cilSave,
  cilSpeedometer,
  cilSun,
  cilTrash,
  cilUser,
  cilXCircle,
  cilExitToApp,
  cilStream,
  cilChevronCircleUpAlt,
  cilChevronCircleDownAlt,
  cilDollar,
  cilPeople,
  cilCloud,
} from '@coreui/icons'
import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

export const icons = {
  sygnet,
  logo,
  logoNegative,
  cilApplicationsSettings,
  cilArrowRight,
  cilNoteAdd,
  cilWallet,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilDrop,
  cilEnvelopeLetter,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilPencil,
  cilPlus,
  cilSave,
  cilSpeedometer,
  cilSun,
  cilTrash,
  cilUser,
  cilXCircle,
  cilExitToApp,
  cilStream,
  cilChevronCircleUpAlt,
  cilChevronCircleDownAlt,
  cilDollar,
  cilPeople,
  cilCloud,
  cibProbot,
  cibLogstash,
}
