import { axiosClient } from '@services/clients'

export async function getTotalDepositGraph(playerId, currency, limit, type) {
  try {
    const {
      data: { result },
    } = await axiosClient(
      `/total-deposits/player/${playerId}?currency=${currency}&filter_type=${type}&limit=${limit}`,
    )

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
