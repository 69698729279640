import {
  postBonusTransaction,
  getTransactions,
  getTopTransactions,
  getUserTransactions,
  getTopPlayers,
  getTransactionsTypes,
} from '@services/queries'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { store } from 'react-notifications-component'
import {
  TRANSACTION_FAILURE,
  TRANSACTION_SUCCESS_AMOUNT,
  TRANSACTION_SUCCESS_BONUS,
} from '@constants'
import { TRANSACTION_ERROR } from '@components/molecules/Player/Players.constants'

export function useAddBonusTransactionMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(postBonusTransaction, {
    onSuccess(data) {
      queryClient.invalidateQueries(['player_balances'])
      queryClient.invalidateQueries(['player_transactions'])
      if (onSuccess) onSuccess(data)
      if (data.data.bonus) {
        store.addNotification(
          TRANSACTION_SUCCESS_BONUS(data.data.currency, data?.data?.bonus),
        )
      }
      if (data.data.amount) {
        store.addNotification(
          TRANSACTION_SUCCESS_AMOUNT(data.data.currency, data?.data?.amount),
        )
      }
    },
    onError(err) {
      console.error('ERROR ADDING BONUS TRANSACTION', err)
      store.addNotification(
        TRANSACTION_FAILURE(
          err?.response?.data?.error?.description
            ? err?.response?.data?.error?.description
            : TRANSACTION_ERROR,
        ),
      )
      if (onError) onError(err)
    },
  })

  return mutationInfo
}

export function useUserTransactions(filterOptions) {
  const { id, initDate, finishDate, wallet, transactionType } = filterOptions
  const info = useQuery(
    ['player_transactions', id, initDate, finishDate, wallet, transactionType],
    () =>
      getUserTransactions(id, initDate, finishDate, wallet, transactionType),
  )

  return info
}

export function useTransactions(
  dateStart,
  dateEnd,
  currency,
  transactionType,
  showPage,
  currentPage,
) {
  const info = useQuery(
    [
      'transactions',
      dateStart,
      dateEnd,
      currency,
      transactionType,
      showPage,
      currentPage,
    ],
    () =>
      getTransactions(
        dateStart,
        dateEnd,
        currency,
        transactionType,
        showPage,
        currentPage,
      ),
  )

  return info
}

export function useTopTransactions(
  dateStart,
  dateEnd,
  currency,
  transactionType,
  showPage,
) {
  const info = useQuery(
    ['transactions', dateStart, dateEnd, currency, transactionType, showPage],
    () =>
      getTopTransactions(
        dateStart,
        dateEnd,
        currency,
        transactionType,
        showPage,
      ),
  )

  return info
}

export function useTransactionsTypes() {
  const info = useQuery(['transactions'], getTransactionsTypes)

  return info
}

export function useTopPlayers(date, players) {
  const info = useQuery(['top_players', date, players], () =>
    getTopPlayers(date, players),
  )

  return info
}
