import { combineReducers } from 'redux'
import sessionReducer from './sessionReducer'
import playerDetailsReducer from './playerDetailsReducer'
import statusReducer from './statusReducer'
import changeState from './uiReducer'
import queryReducer from './queryReducer'

export default combineReducers({
  ui: changeState,
  session: sessionReducer,
  playerDetails: playerDetailsReducer,
  status: statusReducer,
  query: queryReducer,
})
