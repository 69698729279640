/**
 * ROLES & PERMISSIONS
 */

// Super admin role constant
const SUPER_ADMIN = 'superadmin'

/**
 * @brief Checks if a user has a specific role.
 *
 * @param {string} role The role to check for.
 * @return {function(Object): boolean} A function that takes a user object and returns true if the user has the specified role, and false otherwise.
 */
const inRole = (role) => {
  return (user) => user.roles.includes(role)
}

/**
 * @brief Checks if a user has a specific scope.
 *
 * @param {string} scope The scope to check for.
 * @return {function(Object): boolean} A function that takes a user object and returns true if the user has the specified scope, and false otherwise.
 */
const inScope = (scope) => {
  return (user) => user?.scopes?.includes(scope)
}

/**
 * @brief Checks if a user is a super admin.
 *
 * @param {Object} user The user to check.
 * @return {boolean} True if the user is a super admin, and false otherwise.
 */
export const isSuperAdmin = (user) => {
  return user && inRole(SUPER_ADMIN)(user)
}

/**
 * @brief Checks if a user can view a specific scope.
 *
 * @param {Object} user The user to check.
 * @param {string} scope The scope to check.
 * @return {boolean} True if the user can view the specified scope, and false otherwise.
 */
export const isViewScope = (user, scope) => {
  return user && inScope(scope)(user)
}
/**
 * @brief Converts all object keys in the provided object to camelCase.
 *
 * @param {Object|Array} obj The object or array to convert.
 * @return {Object|Array} The converted object or array.
 */
export const camelizeObjectKeys = (obj) => {
  if (obj instanceof Array) {
    return obj.map(camelizeObjectKeys)
  }
  if (obj instanceof Object) {
    return Object.keys(obj).reduce((result, key) => {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => {
        return letter.toUpperCase()
      })
      result[camelKey] = camelizeObjectKeys(obj[key])
      return result
    }, {})
  }
  return obj
}
/**
 * @brief To know if the request is on the list of validations for the backend response messages.
 *
 * @param {string} method The method of the request.
 * @return {Boolean} The bool conditiion
 */
export const getValidationResponse = (method) =>
  method !== 'post' &&
  method !== 'put' &&
  method !== 'patch' &&
  method !== 'delete'
