import { camelizeObjectKeys } from '@utils'
import { axiosClient } from '@services/clients'

export async function getTotalPlayers() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalNewPlayers() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total?days=30`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getActivePlayers() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/active`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getActiveTrends() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/active/trend`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getNewTrends() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/new/trend`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalTrends() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/trend`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalByCountry() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total/country`)
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTopByCountry() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/info/country`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalContinent() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total/continent`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getTotalNewContinent() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/total/continent?days=30`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getPlayersOnline() {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/online`)
    return Promise.resolve(camelizeObjectKeys(result))
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getPlayer(playerId) {
  const {
    data: { result },
  } = await axiosClient(`/players/${playerId}`)

  return result
}

export async function getPlayers() {
  const {
    data: { result },
  } = await axiosClient('/players')

  return result
}

export async function getPlayersStatus() {
  const {
    data: { result },
  } = await axiosClient('/players/status')

  return result
}

export async function getPlayerOnline(playerId) {
  const {
    data: { result },
  } = await axiosClient(`/players/${playerId}/online`)
  return result
}

export async function getPlayersStatusById(statusId) {
  const {
    data: { result },
  } = await axiosClient(`players/status/${statusId}`)
  return result
}

export async function getSelfExclusionInformation(playerId) {
  const {
    data: { result },
  } = await axiosClient(`players/${playerId}/status/self-exclusion`)
  return result
}

export async function UpdatePlayerStatus(data) {
  const dataR = {
    player_status_id: data.player_status_id,
    reason: data.reason,
  }
  const {
    data: { result },
  } = await axiosClient.put(`/players/${data.playerId}/status`, dataR)
  return {
    ...result,
    data,
  }
}

export async function UpdatePlayerStatusDuration(data) {
  const dataR = {
    player_status_id: data.player_status_id,
    reason: data.reason,
    date: data.date,
  }
  const {
    data: { result },
  } = await axiosClient.put(`/players/${data.playerId}/status`, dataR)
  return {
    ...result,
    data,
  }
}

export async function getPlayerChangelog(playerId) {
  const {
    data: { result },
  } = await axiosClient(`/players/changelog/${playerId}`)
  return result
}

export async function getPlayersChangelog(start, end) {
  const {
    data: { result },
  } = await axiosClient('/players/changelog', start, end)

  return result
}

export async function updatePlayer(playerId, data) {
  const {
    data: { result },
  } = await axiosClient.put(`/players/${playerId}`, data)

  return result
}

export async function getPlayersSession(date, players) {
  const {
    data: { result },
  } = await axiosClient(
    `/players/top/session-duration?limit=${players}&days=${date}`,
  )
  return result
}

export async function addNoteForPlayer(data) {
  const {
    data: { result },
  } = await axiosClient.post(`/players/${data.id}/notes`, data)
  return Promise.resolve(result)
}

export async function getNotesPlayer(playerId) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/${playerId}/notes`)

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getNotePlayer(noteId) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/players/notes/${noteId}`)

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function deleteNote(id) {
  const { data } = await axiosClient.delete(`/players/notes/${id}`)
  return data
}

export async function editNote(id, data) {
  const { dataResponse } = await axiosClient.put(`/players/notes/${id}`, data)
  return dataResponse
}

export async function getAnalytics(id) {
  const {
    data: { result },
  } = await axiosClient.get(`/players/${id}/analytics`)

  return result
}
