import Axios from 'axios'

export const axiosClient = Axios.create({
  baseURL: window._env_?.REACT_APP_SERVER_URL ?? '',
  token: sessionStorage.getItem('token'),
})

export const axiosClientRefresh = Axios.create({
  baseURL: window._env_.REACT_APP_SERVER_URL,
  token: sessionStorage.getItem('refreshToken'),
})
