import { axiosClient } from '@services/clients'

export async function getBonuses() {
  const {
    data: { bonuses },
  } = await axiosClient('/bonuses?pageSize=100')

  return bonuses
}

export async function getBonus(bonusId) {
  const { data } = await axiosClient(`/bonus/${bonusId}`)

  return data
}

export async function newBonus(dataB) {
  const {
    data: { result },
  } = await axiosClient.post('/bonus', dataB)
  return result
}
