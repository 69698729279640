import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { axiosClient, axiosClientRefresh } from '@services/clients'
import { setRefreshToken, logoutAction } from '@redux/actions/sessionActions'
import { DOCUMENT_EVENTS, INACTIVITY_TIME } from '@constants'
import { getSessionData } from '@redux/selectors'
import { getValidationResponse } from '@utils'

export default function useAxiosClientAuth() {
  const { token, refreshToken } = useSelector(getSessionData)
  const dispatch = useDispatch()
  // Detect if user is inactive, if so logout
  useEffect(
    function inactivityTime() {
      let time

      function resetTimer() {
        clearTimeout(time)
        time = setTimeout(() => dispatch(logoutAction()), INACTIVITY_TIME)
      }

      // Activate event listeners for all activity events
      DOCUMENT_EVENTS.forEach((event) => {
        document.addEventListener(event, resetTimer, true)
      })

      return function cleanup() {
        DOCUMENT_EVENTS.forEach((event) => {
          document.removeEventListener(event, resetTimer, true)
        })
      }
    },
    [dispatch],
  )

  useEffect(() => {
    const setTokenInterceptor = axiosClient.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
    )
    const setRefreshTokenInterceptor =
      axiosClientRefresh.interceptors.request.use((config) => {
        if (refreshToken) {
          config.headers.Authorization = `Bearer ${refreshToken}`
        }
        return config
      })
    const interceptorResponses = axiosClient.interceptors.response.use(
      undefined,
      (err) => {
        const originalConfig = err.config
        const method = err?.response?.config?.method
        if (err?.response?.status !== 403 && err?.response?.status !== 401) {
          if (getValidationResponse(method))
            window.location.replace('/maintenance')
        }
        if (!originalConfig._retry && err?.response?.status === 403) {
          dispatch(logoutAction())
        }
        if (!originalConfig._retry && err?.response?.status === 401) {
          originalConfig._retry = true
          dispatch(setRefreshToken())
        }
        return () => {
          Promise.reject(err)
        }
      },
    )

    return () => {
      axiosClient.interceptors.request.eject(setTokenInterceptor)
      axiosClient.interceptors.request.eject(interceptorResponses)
      axiosClientRefresh.interceptors.request.eject(setRefreshTokenInterceptor)
    }
  }, [dispatch, refreshToken, token])
}
