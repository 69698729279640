import { getPlayerBalances } from '@services/queries'
import { useQuery } from 'react-query'

export function usePlayerBalances(playerId) {
  const info = useQuery(['player_balances', playerId], () =>
    getPlayerBalances(playerId),
  )

  return info
}
