import { axiosClient } from '@services/clients'

export async function getPlayerBalances(playerId) {
  try {
    const {
      data: { result },
    } = await axiosClient(`/balances/player/${playerId}`)

    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
