import { getTopGames } from '@services/queries'
import { useQuery } from 'react-query'

export function useTopGames(playerId, days, limit) {
  const info = useQuery(['top_games_session', playerId, days, limit], () =>
    getTopGames(playerId, days, limit),
  )

  return info
}
