import {
  getTotalPlayers,
  getActivePlayers,
  getActiveTrends,
  getTotalTrends,
  getTotalByCountry,
  getTopByCountry,
  getTotalContinent,
  getTotalNewPlayers,
  getTotalNewContinent,
  getPlayersOnline,
  getNewTrends,
  getPlayersStatusById,
  getPlayersStatus,
  getPlayerOnline,
  getAnalytics,
  getPlayersSession,
  getPlayersChangelog,
  getPlayerChangelog,
  getPlayers,
  getPlayer,
  updatePlayer,
  addNoteForPlayer,
  getNotesPlayer,
  getNotePlayer,
  deleteNote,
  editNote,
  getSelfExclusionInformation,
  UpdatePlayerStatus,
  UpdatePlayerStatusDuration,
  getStats,
} from '@services/queries'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  STATUS_SUCCESS_MESSAGE,
  STATUS_FAILURE_MESSAGE,
  DETAILS_FAILURE_MESSAGE,
  DETAILS_SUCCESS_MESSAGE,
  NOTES_FAILURE_MESSAGE,
  NOTES_SUCCESS_MESSAGE,
  NOTES_DELETE_FAILURE_MESSAGE,
  NOTES_DELETE_SUCCESS_MESSAGE,
  NOTES_EDIT_FAILURE_MESSAGE,
  NOTES_EDIT_SUCCESS_MESSAGE,
} from '@constants'
import { store } from 'react-notifications-component'

export function usePlayer(playerId) {
  const info = useQuery(['player', playerId], () => getPlayer(playerId))

  return info
}

export function usePlayers() {
  const info = useQuery(['players'], getPlayers)

  return info
}

export function usePlayerChangelog(playerId, enabled = true) {
  const info = useQuery(
    ['players_changelog', playerId],
    () => getPlayerChangelog(playerId),
    {
      enabled,
    },
  )

  return info
}

export function usePlayersChangelog(start, end, enabled = true) {
  const info = useQuery(
    ['players/changelog'],
    () => getPlayersChangelog(start, end),
    {
      enabled,
    },
  )

  return info
}

export function usePlayersStatusDurationMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (statusData) => {
      return UpdatePlayerStatusDuration(statusData.statusObj)
    },
    {
      onSuccess(data, statusData) {
        statusData.handleDispachStatus(statusData.status)
        queryClient.invalidateQueries(['player_status_duration'])
        if (onSuccess) {
          onSuccess(data)
        }

        if (data) store.addNotification(STATUS_SUCCESS_MESSAGE)
      },
      onError(err) {
        console.error('Error updating player status', err)
        store.addNotification(STATUS_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export function usePlayersSession(date, players) {
  const info = useQuery(['session-duration', date, players], () =>
    getPlayersSession(date, players),
  )

  return info
}

export function usePlayerAnalytics(id) {
  const info = useQuery(['analytics_player', id], () => getAnalytics(id))

  return info
}

export function usePlayersStatusMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (statusData) => {
      return UpdatePlayerStatus(statusData.statusObj)
    },
    {
      onSuccess(data, statusData) {
        statusData.handleDispachStatus(statusData.status)
        queryClient.invalidateQueries(['player_status'])
        if (onSuccess) {
          onSuccess(data)
        }
        if (data) store.addNotification(STATUS_SUCCESS_MESSAGE)
      },
      onError(err) {
        console.error('Error updating player status', err)
        if (err) store.addNotification(STATUS_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export const usePlayerOnline = (playerId) => {
  const info = useQuery(['player_online', playerId], () =>
    getPlayerOnline(playerId),
  )

  return info
}

export function usePlayersStatus() {
  const info = useQuery(['player_status'], () => getPlayersStatus())

  return info
}

export function usePlayersStatusId(statusId) {
  const info = useQuery(['player_status_id', statusId], () =>
    getPlayersStatusById(statusId),
  )

  return info
}

export const useTotalPlayers = () => useQuery(['totalPlayers'], getTotalPlayers)

export const useTotalNewPlayers = () => {
  const info = useQuery(['totalNewPlayers'], getTotalNewPlayers)

  return info
}

export const useActivePlayers = () => {
  const info = useQuery(['activePlayers'], getActivePlayers)

  return info
}

export const useActiveTrends = () => {
  const info = useQuery(['activeTrends'], getActiveTrends)

  return info
}

export const useNewTrends = () => {
  const info = useQuery(['newTrends'], getNewTrends)

  return info
}

export const useTotalTrends = () => {
  const info = useQuery(['totalTrends'], getTotalTrends)

  return info
}

export const useTotalByCountry = () => {
  const info = useQuery(['totalByCountry'], getTotalByCountry)

  return info
}

export const useTopByCountry = () => {
  const info = useQuery(['topByCountry'], getTopByCountry)

  return info
}

export const useTotalContinent = () => {
  const info = useQuery(['totalContinent'], getTotalContinent)

  return info
}

export const useTotalNewContinent = () => {
  const info = useQuery(['totalNewContinent'], getTotalNewContinent)

  return info
}

export const useStats = (params) => {
  const info = useQuery(['stats'], () => getStats(params))

  return info
}

export const usePlayersOnline = () => {
  const info = useQuery(['players_online'], getPlayersOnline)

  return info
}

export function useUpdatePlayerMutation(onSuccess, onError) {
  const queryClient = useQueryClient()

  const { data: players = [] } = usePlayers()

  const mutationInfo = useMutation(
    (player) => {
      const playerData = players.find(({ id }) => player.playerId === id)
      return updatePlayer(player.playerId, { ...playerData, ...player.player })
    },
    {
      onSuccess(data, player) {
        player.handleDisPatchPlayer()
        queryClient.invalidateQueries(['players'])
        queryClient.invalidateQueries(['player', player.playerId])
        queryClient.invalidateQueries(['players_changelog', player.playerId])
        if (onSuccess) onSuccess(data)
        if (data) store.addNotification(DETAILS_SUCCESS_MESSAGE)
      },
      onError(err, player) {
        player.handleCancel()
        console.error('ERROR UPDATING PLAYER', err)
        if (onError) onError(err)
        store.addNotification(DETAILS_FAILURE_MESSAGE)
      },
    },
  )

  return mutationInfo
}

export function useNewNotePlayerMutation(onSuccess, onError) {
  const mutationInfo = useMutation(addNoteForPlayer, {
    onSuccess(data) {
      if (onSuccess) onSuccess(data)
      if (data) store.addNotification(NOTES_SUCCESS_MESSAGE)
    },
    onError(err) {
      store.addNotification(NOTES_FAILURE_MESSAGE)
      if (onError) onError(err)
    },
  })

  return mutationInfo
}

export const useNotesPlayer = (playerId) => {
  const info = useQuery(['notes_player', playerId], () =>
    getNotesPlayer(playerId),
  )
  return info
}

export const useNotePlayer = (nodeId) => {
  const info = useQuery(['note_player', nodeId], () => getNotePlayer(nodeId))

  return info
}

export function useDeleteNoteMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (noteData) => {
      return deleteNote(noteData.id)
    },
    {
      onSuccess(data, noteData) {
        if (onSuccess) onSuccess(data)
        queryClient.invalidateQueries(['notes_player', noteData.id])
        store.addNotification(NOTES_DELETE_SUCCESS_MESSAGE)
      },
      onError(err) {
        store.addNotification(NOTES_DELETE_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )
  return mutationInfo
}

export function useEditNoteMutation(onSuccess, onError) {
  const mutationInfo = useMutation(
    ([id, note]) => {
      return editNote(id, note)
    },
    {
      onSuccess(data) {
        if (onSuccess) onSuccess(data)
        store.addNotification(NOTES_EDIT_SUCCESS_MESSAGE)
      },
      onError(err) {
        store.addNotification(NOTES_EDIT_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )
  return mutationInfo
}

export function useSelfExclusionInformation(playerId) {
  const info = useQuery(['status_by_id', playerId], () =>
    getSelfExclusionInformation(playerId),
  )

  return info
}
