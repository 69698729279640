import { axiosClient } from '@services/clients'

export async function getStats(params) {
  try {
    const queries = Object.keys(params)
    const query = queries.map((key) => `${key}=${params[key]}`)
    const queryString = query.join('&')
    const {
      data: { result },
    } = await axiosClient(`/financials/summary?${queryString}`)
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
