import React from 'react'
import { CSpinner } from '@coreui/react'

function Loading() {
  // --- Local state -----------------------------------------------------------
  // --- END: Local state ------------------------------------------------------
  // --- Hooks -----------------------------------------------------------------
  // --- END: Hooks ------------------------------------------------------------
  // --- Redux -----------------------------------------------------------------
  // --- END: Redux ------------------------------------------------------------
  // --- Side effects ----------------------------------------------------------
  // --- END: Side effects -----------------------------------------------------
  // --- Data and handlers -----------------------------------------------------
  // --- END: Data and handlers ------------------------------------------------
  return (
    <div className="pt-3 text-center">
      <CSpinner size="md" variant="grow" />
    </div>
  )
}

export default Loading
