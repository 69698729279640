// Action Types.
import {
  FETCH_LOGIN_ERROR,
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  SET_FIRST_LOGIN_TO_FALSE,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_SUCCESS,
  LOGOUT,
} from '@redux/actions/actionTypes'

const _token = sessionStorage.getItem('token')
const storedUser = sessionStorage.getItem('user')

// Initial State.
const initialState = {
  token: _token || null,
  refreshToken: null,
  user: storedUser ? JSON.parse(storedUser) : null,
  isAuth: false,
  loading: false,
  error: false,
}

const sessionReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case FETCH_LOGIN_SUCCESS:
      const { admin: user, token, refresh_token: refreshToken } = payload
      return {
        ...state,
        user,
        token,
        refreshToken,
        isAuth: true,
        error: false,
      }

    case FETCH_LOGIN_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case SET_FIRST_LOGIN_TO_FALSE:
      return {
        ...state,
        user: {
          ...state.user,
          first_login: false,
        },
      }

    case FETCH_REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        isAuth: false,
        error: false,
      }

    case FETCH_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        token: payload.token,
        refreshToken: payload.refresh_token,
        loading: false,
      }

    case LOGOUT:
      return {
        user: null,
        token: null,
      }

    default:
      return state
  }
}

export default sessionReducer
