import {
  getBonuses,
  getBonus,
  newBonus,
  getUserBonuses,
} from '@services/queries'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { store } from 'react-notifications-component'
import {
  BONUS_CREATION_SUCCESS_MESSAGE,
  BONUS_CREATION_FAILURE_MESSAGE,
} from '@constants'

export function useBonuses() {
  const info = useQuery(['bonuses'], getBonuses)

  return info
}

export function useBonus(bonusId) {
  const info = useQuery(['bonus', bonusId], () => getBonus(bonusId))

  return info
}

export function useNewBonusMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (bonus) => {
      return newBonus(bonus.bonus)
    },
    {
      onSuccess(data, bonus) {
        queryClient.invalidateQueries(['bonuses'])
        bonus.setStatusSubmit(true)

        if (onSuccess) onSuccess(data)

        store.addNotification(BONUS_CREATION_SUCCESS_MESSAGE)
      },
      onError(err, bonus) {
        bonus.setStatusSubmit(false)
        console.error('ERROR UPDATING BONUS', err)
        store.addNotification(BONUS_CREATION_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export function useUserBonuses(playerId) {
  const info = useQuery(['player_Bonuses', playerId], () =>
    getUserBonuses(playerId),
  )

  return info
}
