import { getTotalDepositGraph } from '@services/queries'
import { useQuery } from 'react-query'

export function useUserTotalDeposits(playerId, currency, limit, type) {
  const info = useQuery(
    ['player_total_deposits_graph', playerId, currency, limit, type],
    () => getTotalDepositGraph(playerId, currency, limit, type),
    {
      cacheTime: 0,
    },
  )

  return info
}
