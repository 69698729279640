import { axiosClient } from '@services/clients'

export async function getTopGames(playerId, days, limit) {
  try {
    const {
      data: { result },
    } = await axiosClient(
      `/game-session/top-games/player/${playerId}?days=${days}&limit=${limit}`,
    )
    return Promise.resolve(result)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
