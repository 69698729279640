import {
  getCampaigns,
  getCampaign,
  getCampaignEnabled,
  newCampaign,
} from '@services/queries'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { store } from 'react-notifications-component'
import {
  CAMPAIGN_CREATION_SUCCESS_MESSAGE,
  CAMPAIGN_CREATION_FAILURE_MESSAGE,
  CAMPAIGN_ENABLE_SUCCESS_MESSAGE,
  CAMPAIGN_ENABLE_FAILURE_MESSAGE,
} from '@constants'

export function useCampaigns() {
  const info = useQuery(['campaigns'], getCampaigns)

  return info
}

export function useCampaign(campaignId) {
  const info = useQuery(['campaign', campaignId], () => getCampaign(campaignId))

  return info
}

export function useCampaignEnabledMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    ([enabled, campaignId]) => {
      return getCampaignEnabled(enabled, campaignId)
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['campaigns'])
        if (onSuccess) onSuccess(data)
        store.addNotification(CAMPAIGN_ENABLE_SUCCESS_MESSAGE)
      },
      onError(err) {
        console.error('ERROR UPDATING CAMPAIGN', err)
        store.addNotification(CAMPAIGN_ENABLE_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}

export function useNewCampaignMutation(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(
    (campaign) => {
      return newCampaign(campaign.campaign)
    },
    {
      onSuccess(data, campaign) {
        queryClient.invalidateQueries(['campaigns'])
        campaign.setStatusSubmit({ status: true })
        if (onSuccess) onSuccess(data)
        store.addNotification(CAMPAIGN_CREATION_SUCCESS_MESSAGE)
      },
      onError(err, campaign) {
        campaign.setStatusSubmit({ status: false })
        console.error('ERROR UPDATING CAMPAIGN', err)
        store.addNotification(CAMPAIGN_CREATION_FAILURE_MESSAGE)
        if (onError) onError(err)
      },
    },
  )

  return mutationInfo
}
