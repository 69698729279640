// Action Types.
import {
  INITIAL_DEPOSITS,
  NAVIGATION_MENU_OPTIONS,
} from '@components/molecules/Player/Players.constants'
import {
  SET_CURRENT_PLAYER_SUCCESS,
  SET_CURRENT_PLAYER_ERROR,
  SET_CURRENT_PLAYER_REQUEST,
  SET_CURRENT_PLAYER_OPTION,
  UPDATE_BALANCE_REQUEST,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_ERROR,
  UPDATE_LIMITS_REQUEST,
  UPDATE_LIMITS_SUCCESS,
  UPDATE_LIMITS_ERROR,
  UPDATE_CURRENT_DEPOSIT_SUCCESS,
  UPDATE_CURRENT_DEPOSIT_REQUEST,
  UPDATE_CURRENT_DEPOSIT_ERROR,
  UPDATE_CURRENT_WITHDRAW_SUCCESS,
  UPDATE_CURRENT_WITHDRAW_REQUEST,
  UPDATE_CURRENT_WITHDRAW_ERROR,
} from '@redux/actions/actionTypes'

// Initial State.
const initialState = {
  player: null,
  currentBalance: null,
  currency: null,
  loading: false,
  limits: null,
  currentDeposit: INITIAL_DEPOSITS,
  currentWithdraw: INITIAL_DEPOSITS,
  selectedOption: null,
}

Object.assign(initialState, {
  ...initialState,
  selectedOption: { ...NAVIGATION_MENU_OPTIONS[0], nestedIndex: 0 },
})

const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PLAYER_SUCCESS:
      return { ...state, player: action.payload, loading: false }

    case SET_CURRENT_PLAYER_REQUEST:
      return { ...state, loading: true }

    case SET_CURRENT_PLAYER_ERROR:
      return { ...state, loading: false }

    case SET_CURRENT_PLAYER_OPTION:
      return { ...state, selectedOption: action.payload }

    case UPDATE_BALANCE_SUCCESS:
      return {
        ...state,
        currentBalance:
          action.payload.player_id === state.player.id
            ? action.payload.amount
            : state.currentBalance,
        currency:
          action.payload.player_id === state.player.id
            ? action.payload.currency_code
            : state.currency,
        loading: false,
      }

    case UPDATE_BALANCE_REQUEST:
      return { ...state, loading: true }

    case UPDATE_BALANCE_ERROR:
      return { ...state, loading: false }

    case UPDATE_LIMITS_SUCCESS:
      return {
        ...state,
        limits: action.payload,
        loading: false,
      }

    case UPDATE_LIMITS_REQUEST:
      return { ...state, loading: true }

    case UPDATE_LIMITS_ERROR:
      return { ...state, loading: false }

    case UPDATE_CURRENT_DEPOSIT_SUCCESS:
      return {
        ...state,
        currentDeposit: action.payload,
        loading: false,
      }

    case UPDATE_CURRENT_DEPOSIT_REQUEST:
      return { ...state, loading: true }

    case UPDATE_CURRENT_DEPOSIT_ERROR:
      return { ...state, loading: false }

    case UPDATE_CURRENT_WITHDRAW_SUCCESS:
      return {
        ...state,
        currentWithdraw: action.payload,
        loading: false,
      }

    case UPDATE_CURRENT_WITHDRAW_REQUEST:
      return { ...state, loading: true }

    case UPDATE_CURRENT_WITHDRAW_ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}

export default usersListReducer
