/**
 * @brief Formats a fiat balance into a human-readable string with two decimal places and comma separators.
 *
 * @param balance The balance to format.
 * @returns The formatted balance string.
 */
export function formatFiat(balance) {
  if (balance) {
    return Number(balance)
      ?.toFixed(2)
      ?.toString()
      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }

  return '0.00'
}
/**
 * @brief Formats a points balance into a human-readable string with comma separators.
 *
 * @param balance The balance to format.
 * @returns The formatted balance string.
 */
export function formatPoints(balance) {
  if (balance) {
    return Number(Math.ceil(balance))
      ?.toString()
      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }
  return '0'
}
/**
 * @brief Formats a cryptocurrency balance into a human-readable string with eight decimal places and comma separators.
 *
 * @param balance The balance to format.
 * @returns The formatted balance string.
 */
export function formatCrypto(balance) {
  if (balance) {
    return Number(balance)
      ?.toFixed(8)
      ?.toString()
      ?.replace(/(\d)(?=()+(?!))/g, '$1,')
  }

  return '0.00000000'
}
/**
 * @brief Formats a balance into a human-readable string with appropriate formatting for the given cryptocurrency code.
 *
 * @param balance The balance to format.
 * @param code The cryptocurrency code.
 * @returns The formatted balance string.
 */
export function numberWithCommas(balance, code) {
  if (code === 'BTC') {
    return formatCrypto(balance)
  }
  return formatFiat(balance)
}
