// Action Types.
import {
  SET_CURRENT_QUERY_ERROR,
  SET_CURRENT_QUERY_SUCCESS,
  SET_CURRENT_QUERY_REQUEST,
} from '@redux/actions/actionTypes'

// Initial State.
const initialState = {
  status: false,
  loading: false,
}

const queryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_QUERY_SUCCESS:
      return { ...state, status: action.payload, loading: false }

    case SET_CURRENT_QUERY_REQUEST:
      return { ...state, loading: true }

    case SET_CURRENT_QUERY_ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}

export default queryReducer
