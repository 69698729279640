import axios from 'axios'
import { store } from 'react-notifications-component'
import { refreshToken } from '@services/queries'
import { GENERIC_ERROR_NOTIFICATION } from '@constants'
import { error, received, request } from './baseActions'
// Action Types
import {
  FETCH_LOGIN_ERROR,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_REQUEST,
  SET_FIRST_LOGIN_TO_FALSE,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_SUCCESS,
  LOGOUT,
} from './actionTypes'
import { ERROR_NOTIFICATION } from './actions.constants'

export const setFirstLoginToFalse = () => (dispatch) => {
  dispatch(received(SET_FIRST_LOGIN_TO_FALSE))
}

export const logoutAction = () => (dispatch) => {
  dispatch(request(LOGOUT))
  sessionStorage.setItem('time', null)
}

// eslint-disable-next-line import/prefer-default-export
export const fetchLogin =
  ({ email, password }) =>
  async (dispatch) => {
    // Dispatching our request action.
    dispatch(request(FETCH_LOGIN_REQUEST))

    const URL = `${window._env_.REACT_APP_SERVER_URL}admins/login`

    try {
      const response = await axios.post(URL, { username: email, password })
      const {
        token,
        refresh_token: _refreshToken,
        admin,
      } = response.data?.result ?? {}
      dispatch(received(FETCH_LOGIN_SUCCESS, response.data?.result))
      sessionStorage.setItem('token', token)
      sessionStorage.setItem('refreshToken', _refreshToken)
      sessionStorage.setItem('user', JSON.stringify(admin))
      sessionStorage.setItem('time', new Date())
    } catch (err) {
      const {
        response: { status },
      } = err
      // eslint-disable-next-line no-console
      store.addNotification(
        ERROR_NOTIFICATION?.[status] ?? GENERIC_ERROR_NOTIFICATION,
      )
      dispatch(error(FETCH_LOGIN_ERROR))
    }
  }

export const setRefreshToken = () => async (dispatch) => {
  dispatch(received(FETCH_REFRESH_TOKEN_REQUEST, {}))
  try {
    const data = await refreshToken()
    dispatch(received(FETCH_REFRESH_TOKEN_SUCCESS, data.result))
    sessionStorage.setItem('time', new Date())
  } catch (err) {
    dispatch(logoutAction())
  }
}
