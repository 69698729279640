import { axiosClient, axiosClientRefresh } from '@services/clients'

export async function getAdmins() {
  const {
    data: { result },
  } = await axiosClient('/admins')

  return result
}

export async function refreshToken() {
  try {
    const { data } = await axiosClientRefresh.post('/admins/refresh')
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}
