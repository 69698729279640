export const getPlayers = (state) =>
  state.usersList.users ? state.usersList.users : null

export const getPlayer = ({ playerDetails }) => playerDetails
export const getSessionData = ({ session }) => session
export const getSessionToken = (state) => getSessionData(state)?.token
export const getSessionUser = (state) => getSessionData(state)?.user
export const getSessionLoading = (state) => getSessionData(state)?.loading
export const selectSessionPlayer = (state) => getPlayer(state)?.player
export const selectPlayerDefaultCurrency = (state) =>
  selectSessionPlayer(state)?.currency_id
export const selectPlayerLimits = (state) => getPlayer(state)?.limits
export const selectPlayerCurrentDeposit = (state) =>
  getPlayer(state)?.currentDeposit
export const selectPlayerCurrentWithdraw = (state) =>
  getPlayer(state)?.currentWithdraw

export const getPlayerSelectedOption = (state) =>
  getPlayer(state)?.selectedOption

export const getUIDarkMode = ({ ui }) => ui?.darkMode
export const getUISidebarShow = ({ ui }) => ui?.sidebarShow

export const getStatus = (state) => state?.status

export const getQueryStatus = (state) => state?.query?.status
export const getQueryLoading = (state) => state?.query?.loading
