import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

function ProtectedRoute({ component, fallback, auth, path, exact = false }) {
  return (
    <Route component={auth ? component : fallback} exact={exact} path={path} />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  fallback: PropTypes.any,
  auth: PropTypes.bool.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
}

ProtectedRoute.defaultProps = {
  fallback: null,
  exact: false,
}

export default ProtectedRoute
