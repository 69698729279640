import { monthNames } from '@constants'

/**
 * @brief Formats a date string into a human-readable format with the day, month, and year.
 *
 * @param dateString The date string to format.
 * @returns The formatted date string.
 */
export function formatDateProfile(dateString) {
  const date = new Date(dateString?.slice(0, 10))
  const day = date.getDate() + 1
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  const dateFormatted = date ? `${day} ${month} ${year}` : ''

  return dateFormatted
}

/**
 * @brief Formats a date string into a human-readable format according to UK standards, including the time zone.
 *
 * @param dateString The date string to format.
 * @returns The formatted date string in UK format.
 */
export function formatDateUK(dateString) {
  const date = new Date(dateString)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  }
  const ukTime = date.toLocaleDateString('en-GB', options)

  return ukTime?.slice(0, 28).replace('at ', '')
}
