import { error, received, request } from './baseActions'

// Action Types
import {
  SET_CURRENT_PLAYER_ERROR,
  SET_CURRENT_PLAYER_SUCCESS,
  SET_CURRENT_PLAYER_REQUEST,
  SET_CURRENT_STATUS_ERROR,
  SET_CURRENT_STATUS_SUCCESS,
  SET_CURRENT_STATUS_REQUEST,
  SET_CURRENT_PLAYER_OPTION,
  UPDATE_BALANCE_REQUEST,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_ERROR,
  UPDATE_LIMITS_REQUEST,
  UPDATE_LIMITS_SUCCESS,
  UPDATE_LIMITS_ERROR,
  UPDATE_CURRENT_DEPOSIT_SUCCESS,
  UPDATE_CURRENT_DEPOSIT_REQUEST,
  UPDATE_CURRENT_DEPOSIT_ERROR,
  UPDATE_CURRENT_WITHDRAW_SUCCESS,
  UPDATE_CURRENT_WITHDRAW_REQUEST,
  UPDATE_CURRENT_WITHDRAW_ERROR,
} from './actionTypes'

// Update player status
export const setActualStatus = (status) => (dispatch) => {
  dispatch(request(SET_CURRENT_STATUS_REQUEST))
  if (status !== null) {
    dispatch(received(SET_CURRENT_STATUS_SUCCESS, status))
  } else {
    dispatch(error(SET_CURRENT_STATUS_ERROR))
  }
}

export const setActualPlayerOption = (option) => (dispatch) => {
  dispatch(received(SET_CURRENT_PLAYER_OPTION, option))
}

// Will send token once it's required in the API
export const setActualPlayer = (player) => async (dispatch) => {
  dispatch(request(SET_CURRENT_PLAYER_REQUEST))
  if (player !== null) {
    dispatch(received(SET_CURRENT_PLAYER_SUCCESS, player))
  } else {
    dispatch(error(SET_CURRENT_PLAYER_ERROR))
  }
}

export const updateBalance = (playerBalance) => (dispatch) => {
  dispatch(request(UPDATE_BALANCE_REQUEST))
  if (playerBalance !== null) {
    dispatch(received(UPDATE_BALANCE_SUCCESS, playerBalance))
  } else {
    dispatch(error(UPDATE_BALANCE_ERROR))
  }
}

export const updateLimits = (limits) => (dispatch) => {
  dispatch(request(UPDATE_LIMITS_REQUEST))
  if (limits !== null) {
    dispatch(received(UPDATE_LIMITS_SUCCESS, limits))
  } else {
    dispatch(error(UPDATE_LIMITS_ERROR))
  }
}

export const updateCurrentDeposit = (amount) => (dispatch) => {
  dispatch(request(UPDATE_CURRENT_DEPOSIT_REQUEST))
  if (amount !== null) {
    dispatch(received(UPDATE_CURRENT_DEPOSIT_SUCCESS, amount))
  } else {
    dispatch(error(UPDATE_CURRENT_DEPOSIT_ERROR))
  }
}

export const updateCurrentWithdraw = (amount) => (dispatch) => {
  dispatch(request(UPDATE_CURRENT_WITHDRAW_REQUEST))
  if (amount !== null) {
    dispatch(received(UPDATE_CURRENT_WITHDRAW_SUCCESS, amount))
  } else {
    dispatch(error(UPDATE_CURRENT_WITHDRAW_ERROR))
  }
}
